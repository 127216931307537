import { checkRut } from "react-rut-formatter";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export default function validateFormInputs(inputs) {
    
    let errors = {};
    if ( ('dni' in inputs) && inputs.dni === ''){
        errors.dni = 'El Rut es requerido'
    }
    if ( ('dni' in inputs) && !checkRut(inputs.dni) ){
        errors.dni = 'El Rut no es valido'
    }
    if ( ('firstName' in inputs) && inputs.firstName === '' ){
        errors.firstName = 'Nombres'
    }
    if ( ('lastName' in inputs) && inputs.lastName === '' ){
        errors.lastName = 'Apellido paterno'
    }
    if( ('birthDay' in inputs) && (inputs.birthDay === 'Invalid date' || inputs.birthDay === '') ) {
        errors.birthDay = 'Fecha de nacimiento';
    }
    if ( ('day' in inputs) && inputs.day === '' ){
        errors.day = 'Día'
    }
    if ( ('month' in inputs) && inputs.month === '' ){
        errors.month = 'Mes'
    }
    if ( ('year' in inputs) && inputs.year === '' ){
        errors.year = 'Año'
    }
    if (
        inputs.day !== '' &&
        inputs.month !== '' &&
        inputs.year !== ''
    ) {
        dayjs.extend(customParseFormat);
        const currentYear = dayjs().year();
        const isValid = dayjs(`${inputs.year}-${inputs.month}-${inputs.day}`, ['YYYY-MM-DD', 'YYYY-M-D', 'YYYY-MM-D', 'YYYY-M-DD'], true).isValid();
        if (!isValid) {
            errors.birthDay = 'La fecha de nacimiento no es valida, el formato debe ser por Ej: 01-01-1980';
        }
        if (isValid && (Number(inputs.year) >= Number(currentYear))) {
            errors.birthDay = 'La fecha de nacimiento no es valida, el año de nacimiento no puede ser igual o mayor al año actual';
        }
        if (isValid && (Number(inputs.year) <= 1900)) {
            errors.birthDay = 'La fecha de nacimiento no es valida, el año de nacimiento no menor o igual a 1900';
        }
    }
    if( ('gender' in inputs) && inputs.gender === '' ) {
        errors.gender = 'Género';
    }
    if( ('email' in inputs) && (inputs.email === '' || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)) ){
        errors.email = 'Email';
    } 
    if( ('pre' in inputs) && inputs.pre === '' ) {
        errors.pre = 'Código del teléfono';
    }
    if( ('phone' in inputs) && inputs.phone === '' ) {
        errors.phone = 'Teléfono celular';
    }
    if( ('phone' in inputs) && inputs.phone.length > 1 && (inputs.phone.length > 9 || inputs.phone.length < 9) ) {
        errors.phone = 'Teléfono celular';
    }
    if( ('street' in inputs) && inputs.street === '' ) {
        errors.street = 'Dirección';
    }
    if( ('region' in inputs) && inputs.region === '' ) {
        errors.region = 'Región';
    }    
    if( ('commune' in inputs) && inputs.commune === '' ) {
        errors.commune = 'Comuna';
    }
    // if( ('healthForecast' in inputs) && inputs.healthForecast === '' ) {
    //     errors.healthForecast = 'requerido**';
    // }
    return errors;
}    