import { createSlice } from '@reduxjs/toolkit';

export const registerPatientSlice = createSlice({
    name: 'registerPatient',
    initialState: {
        loading: false,
        verifyPatient: null,
        create: null,
        msgCreate: null
    },
    reducers: {
        initVerifyRutPatient: state => {
            state.loading = true;
            state.verifyPatient = null;
        },
        verifyRutPatient: (state, action) => {
            state.loading = false;
            state.verifyPatient = action.payload;
        },
        initCreatePatient: state => {
            state.loading = true;
        },
        createPatient: (state, action) => {
            state.loading = false;
            state.create = action.payload.create;
            state.msgCreate = action.payload.msg;
        },
        clearCreateStatus: state => {
            state.create = null;
        },
    }
});

export const { 
    initVerifyRutPatient,
    verifyRutPatient,
    initCreatePatient,
    createPatient,
    clearCreateStatus,
 } = registerPatientSlice.actions;