import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

const ErrorMessage = ({field, message, status}) => {
    return ( 
        <Alert variant="danger" show={status}>
            <>
            {field === 'phone' ? (
                <p><strong>{message}</strong> es requerido y de 9 dígitos</p>
            ) : field === 'email' ? (
                <p><strong>{message}</strong> es requerido y de debe ser un email valido</p>
            ) : field === 'birthDay' ? (
                <p><strong>{message}</strong></p>    
            ) : (
                <p><strong>{message}</strong> es requerido</p>
            )}
            </>
        </Alert>
    );
}
 
export default ErrorMessage;