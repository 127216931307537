import { verifyRut, registerPatient } from "./services";
import { 
    initVerifyRutPatient, 
    verifyRutPatient, 
    initCreatePatient, 
    createPatient,
    clearCreateStatus 
} from "./registerPatientSlice";

export const verifyRutPatientAction = (dni) => {
    return async dispatch => {
        dispatch(initVerifyRutPatient());
        const response = await verifyRut(dni);
        if (response.status === 200) {
            dispatch(verifyRutPatient(true));
        } else {
            dispatch(verifyRutPatient(false));
        }
    }
}

export const createPatientAction = (body) => {
    return async dispatch => {
        dispatch(initCreatePatient());
        const response = await registerPatient(body);
        dispatch(createPatient(response.data));
    }
}

export const clearCreateStatusAction = () => {
    return dispatch => {
        dispatch(clearCreateStatus());
    }
}