import { useState } from 'react'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { HappContainer } from 'components/CustomStyledComponents/CustomStyledComponents';
import { Row, Col, Button, Form } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import logo from 'assets/img/imag_.png';
import Loader from 'components/Loaders/Loader';
import SubmitLoader from 'components/Loaders/SubmitLoader';
import ErrorMessage from 'components/Alerts/ErrorMessage';
import SweetAlert2 from 'react-sweetalert2';

//custom hook
import useFormNewPatient from 'hooks/useFormNewPatient/useFormNewPatient';

//initState
const initState = {
    dni: '',
    firstName: '',
    lastName: '',
    lastName2: '',
    day: '',
    month: '',
    year: '',
    gender: '',
    healthForecast: '',
    email: '',
    pre: '',
    phone: '',
    street: '',
    commune: '',
    region: '',
    company: '',
    branchOfficeId: '',
    position: '',
    address: [],
}

const NewPatient = () => {
    const [confirmCreatePatientMessage, setConfirmCreatePatientMessage] = useState({
        show: true,
        text: '¡Perfil creado con éxito! Estas listo para poder realizar tus exámenes clínicos',
        icon: 'success',
    });
    const [patientexistsMessage, setPatientexistsMessage] = useState({
        show: true,
        text: 'El rut ingresado ya esta registrado',
        icon: 'error',
    });
    const [errorCreatePatient, setErrorCreatePatient] = useState({
        show: true,
        icon: 'error',
    });
    const [errorBaseUrl, setErrorBaseUrl] = useState({
        show: true,
        icon: 'error',
    });
    const {
        state,
        showForm,
        showLoader,
        spinnerSubmit,
        errors,
        comunes,
        stateReg,
        statusCreate,
        errorBaseUrlStatus,
        msgCreate,
        verifyRutPatientMsg,
        showErrorMessages,
        onClickShowForm,
        handlerChange,
        handlerChangeDni,
        handlerChangeReg,
        handlerChangeBirthDay,
        handlerChangeNumber,
        handlerSubmit,
    } = useFormNewPatient(initState);

    const {
        dni,
        firstName,
        lastName,
        lastName2,
        day,
        month,
        year,
        gender,
        healthForecast,
        email,
        pre,
        phone,
        street,
        commune,
        region,
    } = state;
    return (
        <Row className="mt-content ">
            <div className="content-logo logo-login mt-3">
                <img src={logo} alt="description"/>
            </div>
            <HappContainer>
                {errorBaseUrlStatus !== null && errorBaseUrlStatus === true ? (
                    <>
                    <SweetAlert2 {...errorBaseUrl}>
                        <p>El formulario de creación no esta disponible</p>
                    </SweetAlert2> 
                    <Row className='row-content mt-4 mb-4'>
                        <Col sm={12} lg={12} md={12} className='col-content text-center'>
                            <small className='slogan-header'>Formulario completo. Te invitamos a realizarte tus exámenes.</small>
                        </Col>
                    </Row>   
                </>
                ) : (
                    <>
                        {errorBaseUrlStatus !== null && !errorBaseUrlStatus ? (
                            <>
                                {statusCreate !== null && !statusCreate ? (
                                    <SweetAlert2 {...errorCreatePatient}>
                                        <p>{msgCreate}</p>
                                    </SweetAlert2>    
                                ) : null}
                                {verifyRutPatientMsg !== null && verifyRutPatientMsg ? (
                                    <SweetAlert2
                                    {...patientexistsMessage}
                                    />
                                ) : null}
                                {statusCreate !== null && statusCreate ? (
                                    <>
                                        <SweetAlert2
                                            {...confirmCreatePatientMessage}
                                        />
                                        <Row className='row-content mt-4 mb-4'>
                                            <Col sm={12} lg={12} md={12} className='col-content text-center'>
                                                <small className='slogan-header'>Formulario completo. Ahora, pasa a realizar tus exámenes</small>
                                            </Col>
                                        </Row>   
                                    </>
                                ) : statusCreate === null || !statusCreate ? (
                                    <Form
                                        onSubmit={handlerSubmit}
                                    >
                                        <Row className='row-content mt-4 mb-4'>
                                            <Col sm={12} lg={12} md={12} className='col-content'>
                                                <small className='slogan-header'>Ingresa tu Rut, completa tu perfil y reduce el tiempo de espera para realizar tus exámenes</small>
                                            </Col>
                                        </Row>
                                        <Row className='row-content mt-4 mb-4'>
                                            <Col sm={6} lg={3} md={3} className='dni-col-content'>
                                                <Form.Group controlId="dni">
                                                    <Form.Label className='label-input'>Rut</Form.Label>
                                                    <Form.Control
                                                        className={errors && errors.dni ? 'border-require' : ''}
                                                        type="text"
                                                        name="dni"
                                                        onChange={handlerChangeDni}
                                                        value={dni ? dni : ''}
                                                        placeholder="ej: 11111111-1"
                                                    />
                                                </Form.Group>
                                                <p className='p-error-profile-col'>{errors.dni && errors.dni}</p>
                                            </Col>
                                            {!showForm ? (
                                                <Col sm={6} lg={3} md={3} className='col-button'>
                                                    <Button
                                                        onClick={onClickShowForm}
                                                        className='primary-bg-color'
                                                        size="ms"
                                                    >
                                                        Continuar
                                                    </Button>
                                                </Col>
                                            ) : null}
                                        </Row>
                                        <Loader show={showLoader} /> 
                                        {showForm && !showLoader ? (
                                            <>
                                                <Row className='row-content mt-4'>
                                                    <Col sm={12} lg={4} md={4} className='col-content'>
                                                        <Form.Group controlId="firstName">
                                                            <Form.Label className='label-input'>Nombres <span className='require-input'>*</span></Form.Label>
                                                            <Form.Control
                                                                className={errors && errors.firstName ? 'border-require' : ''}
                                                                type="text"
                                                                name="firstName"
                                                                onChange={handlerChange}
                                                                value={firstName ? firstName : ''}
                                                                placeholder="Nombres"
                                                            />
                                                        </Form.Group>
                                                        {/* <p className='p-error-profile-col'>{errors.firstName && errors.firstName}</p> */}
                                                    </Col>
                                                    <Col sm={12} lg={4} md={4} className='col-content'>
                                                        <Form.Group controlId="lastName">
                                                            <Form.Label className='label-input'>Apellido Paterno <span className='require-input'>*</span></Form.Label>
                                                            <Form.Control
                                                                className={errors && errors.lastName ? 'border-require' : ''}
                                                                type="text"
                                                                name="lastName"
                                                                onChange={handlerChange}
                                                                value={lastName ? lastName : ''}
                                                                placeholder="Apellido Paterno"
                                                            />
                                                        </Form.Group>
                                                        {/* <p className='p-error-profile-col'>{errors.lastName && errors.lastName}</p> */}
                                                    </Col>
                                                    <Col sm={12} lg={4} md={4} className='col-content'>
                                                        <Form.Group controlId="lastName2">
                                                            <Form.Label className='label-input'>Apellido Materno</Form.Label>
                                                            <Form.Control
                                                                // className={errors && errors.firstName ? 'border-require' : ''}
                                                                type="text"
                                                                name="lastName2"
                                                                onChange={handlerChange}
                                                                value={lastName2 ? lastName2 : ''}
                                                                placeholder="Apellido Materno"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className='row-content mt-4 mt-4-mobile'>
                                                    {/* <Col sm={12} lg={4} md={4} className='col-content'>
                                                        <Form.Group className='d-grid' controlId="">
                                                            <Form.Label className='label-input'>Fecha de Nacimiento <span className='require-input'>*</span></Form.Label>
                                                            <DatePicker 
                                                                className={`input-date ${errors && errors.birthDay ? 'border-input-date' : ''}`} 
                                                                onChange={handlerChangeBirthDay} 
                                                                value={birthDay ? birthDay : ''} 
                                                            />
                                                        </Form.Group>
                                                        <p className='p-error-profile-col'>{errors.birthDay && errors.birthDay}</p>
                                                    </Col> */}
                                                    <Col sm={12} lg={4} md={4} className='col-content'>
                                                        <Form.Label className='label-input'>Fecha de Nacimiento <small>Ej: 01-01-1980</small><span className='require-input'>*</span></Form.Label>
                                                        <div className='content-birthday'>
                                                            <Form.Group className='bd-input' controlId="day">
                                                                <Form.Control
                                                                    className={errors && errors.day ? 'border-require' : ''}
                                                                    type="text"
                                                                    name="day"
                                                                    onChange={handlerChangeNumber}
                                                                    value={day ? day : ''}
                                                                    maxLength="2"
                                                                    placeholder="Día"
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className='bd-input' controlId="">
                                                                <Form.Control
                                                                    className={errors && errors.month ? 'border-require' : ''}
                                                                    type="text"
                                                                    name="month"
                                                                    onChange={handlerChangeNumber}
                                                                    value={month ? month : ''}
                                                                    maxLength="2"
                                                                    placeholder="Mes"
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className='bd-input' controlId="">
                                                                <Form.Control
                                                                    className={errors && errors.year ? 'border-require' : ''}
                                                                    type="text"
                                                                    name="year"
                                                                    onChange={handlerChangeNumber}
                                                                    value={year ? year : ''}
                                                                    maxLength="4"
                                                                    placeholder="Año"
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} lg={3} md={3} className='col-content'>
                                                        <Form.Group  controlId="gender">
                                                            <Form.Label className='label-input'>Género <span className='require-input'>*</span></Form.Label>
                                                            <Form.Select
                                                                className={errors && errors.gender ? 'border-require' : ''}
                                                                name="gender" 
                                                                onChange={handlerChange}
                                                                value={gender ? gender : ''} 
                                                            >
                                                                <option value="">Seleccione género</option>
                                                                <option value="M">Masculino</option>
                                                                <option value="F">Femenino</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                        {/* <p className='p-error-profile-col'>{errors.gender && errors.gender}</p> */}
                                                    </Col>
                                                    <Col sm={12} lg={5} md={5} className='col-content'>
                                                    <Form.Group  controlId="healthForecast">
                                                        <Form.Label className='label-input'>Previsión de Salud</Form.Label>
                                                            <Form.Select 
                                                                name="healthForecast"
                                                                className={errors && errors.healthForecast ? 'border-require' : ''}
                                                                onChange={handlerChange}
                                                                value={healthForecast !== '' ? healthForecast : ''} 
                                                            >
                                                                    <option value="">Seleccione una previsión de salud</option>
                                                                    <option value="Fonasa">Fonasa</option>
                                                                    <option value="Isapre Colmena">Isapre Colmena</option>
                                                                    <option value="Isapre Cruz Blanca">Isapre Cruz Blanca</option>
                                                                    <option value="Isapre Banmédica">Isapre Banmédica</option>
                                                                    <option value="Isapre Consalud">Isapre Consalud</option>
                                                                    <option value="Isapre Vida tres">Isapre Vida tres</option>
                                                                    <option value="Isapre Esencial">Isapre Esencial</option>
                                                                    <option value="Isapre Nueva masvida">Isapre Nueva masvida</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                        {/* <p className='p-error-profile-col'>{errors.healthForecast && errors.healthForecast}</p> */}
                                                    </Col>
                                                </Row>
                                                <Row className='row-content mt-4 mt-4-mobile'>
                                                    <Col sm={12} lg={6} md={6} className='col-content'>
                                                        <Form.Group controlId="email">
                                                            <Form.Label className='label-input'>Correo Electrónico <span className='require-input'>*</span></Form.Label>
                                                            <Form.Control 
                                                                type="email" 
                                                                className={errors && errors.email ? 'border-require' : ''}
                                                                onChange={handlerChange}
                                                                value={email ? email : ''} 
                                                                name="email" 
                                                                placeholder="Correo Electronico" 
                                                            />
                                                        </Form.Group>
                                                        {/* <p className='p-error-profile-col'>{errors.email && errors.email}</p> */}
                                                    </Col>
                                                    <Col sm={12} lg={6} md={6} className='content-phone col-content'>
                                                        <div className='pre-phone'>
                                                            <Form.Group controlId="pre">
                                                                <Form.Label className='label-input'>Código <span className='require-input'>*</span></Form.Label>
                                                                <Form.Control 
                                                                    type="text"
                                                                    onChange={handlerChangeNumber}
                                                                    value={pre ? pre : ''} 
                                                                    className={errors && errors.pre ? 'border-require' : ''}
                                                                    maxLength="2"
                                                                    name="pre"
                                                                    placeholder='56'
                                                                />
                                                            </Form.Group>
                                                            {/* <p className='p-error-profile-col p-error-pre'>{errors.pre && errors.pre}</p> */}
                                                        </div>
                                                        <div className="phone">
                                                            <Form.Group controlId="phone">
                                                                <Form.Label className='label-input'>Teléfono Celular <span className='require-input'>*</span></Form.Label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    className={errors && errors.phone ? 'border-require' : ''}
                                                                    onChange={handlerChangeNumber}
                                                                    maxLength="9"
                                                                    value={phone? phone : ''} 
                                                                    name="phone" 
                                                                    placeholder="Teléfono" 
                                                                />
                                                            </Form.Group>
                                                            {/* <p className='p-error-profile-col p-error-phone'>{errors.phone && errors.phone}</p> */}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className='row-content mt-4 mt-4-mobile'>
                                                    <Col sm={12} lg={5} md={5} className='col-content'>
                                                        <Form.Group controlId="street">
                                                            <Form.Label className='label-input'>Dirección de domicilio <span className='require-input'>*</span></Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                onChange={handlerChange}
                                                                className={errors && errors.street ? 'border-require' : ''}
                                                                value={street? street : ''} 
                                                                name="street" 
                                                                placeholder="Dirección" 
                                                            />
                                                        </Form.Group>
                                                        {/* <p className='p-error-profile-col p-error-phone'>{errors.street && errors.street}</p> */}
                                                    </Col>
                                                    <Col sm={12} lg={4} md={4} className='col-content'>
                                                        <Form.Group  controlId="region">
                                                            <Form.Label className='label-input'>Región <span className='require-input'>*</span></Form.Label>
                                                            <Form.Select 
                                                                name="region"
                                                                className={errors && errors.region ? 'border-require' : ''}
                                                                onChange={handlerChangeReg}
                                                                value={region ? region : ''}
                                                            >
                                                                <option value="">Seleccione una región</option>
                                                                {stateReg && Object.keys(stateReg).length > 0 ? (
                                                                    stateReg.map(reg => (
                                                                        <option key={reg.id} value={reg.name}>{reg.name}</option>
                                                                    ))    
                                                                ) : null}
                                                            </Form.Select>
                                                        </Form.Group>
                                                        {/* <p className='p-error-profile-col'>{errors.region && errors.region}</p> */}
                                                    </Col>
                                                    <Col sm={12} lg={3} md={3} className='col-content'>
                                                        <Form.Group  controlId="commune">
                                                            <Form.Label className='label-input'>Comuna <span className='require-input'>*</span></Form.Label>
                                                            <Form.Select 
                                                                name="commune" 
                                                                className={errors && errors.commune ? 'border-require' : ''}
                                                                value={commune ? commune : ''}
                                                                onChange={handlerChange}
                                                                >
                                                                <option value="">Seleccione una comuna</option>
                                                                {comunes && Object.keys(comunes).length > 0 ? (
                                                                comunes.map(comuna => (
                                                                    <option key={comuna.id} value={comuna.name}>{comuna.name}</option>
                                                                ))
                                                                ) : null}
                                                            </Form.Select>
                                                        </Form.Group>
                                                        {/* <p className='p-error-profile-col'>{errors.commune && errors.commune}</p> */}
                                                    </Col>
                                                </Row>
                                                {showErrorMessages ? (
                                                    <Row className='row-content mt-4 mb-3'>
                                                        <Col sm={12} lg={12} md={12} className='col-content'>
                                                            {Object.keys(errors).map((error, pos) => (
                                                                <ErrorMessage
                                                                    key={pos}
                                                                    field={error} 
                                                                    message={errors[error]}
                                                                    status={showErrorMessages}
                                                                />
                                                            ))}
                                                        </Col>
                                                    </Row>
                                                ) : null}
                                                {spinnerSubmit ? (
                                                    <SubmitLoader show={spinnerSubmit} /> 
                                                ) : null}
                                                <Row className='row-content mt-4 mb-3'>
                                                    <Col className='text-center' sm={12} lg={12} md={12}>
                                                        <Button
                                                            className='primary-bg-color'
                                                            size="lg"
                                                            type="submit"
                                                        >
                                                            Registrarme
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : null}
                                    </Form>
                                ) : null}
                            </>                            
                        ) : null}
                    </>
                )}
            </HappContainer>
        </Row> 
     );
}
 
export default NewPatient;