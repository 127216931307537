import React from 'react';
import {
    Routes,
    Route,
    useNavigate
} from 'react-router-dom';

//views
import Home from 'views/Home/Home';
import NewPatient from 'views/NewPatient/NewPatient';

const RoutesMap = () => {
    const RoutesWithoutNavBar = ({ children }) => {
        return children;
    }
    return ( 
        <Routes>
            <Route
                path="/"
                element={
                    <RoutesWithoutNavBar>
                        <Home />
                    </RoutesWithoutNavBar>
                }
            />
            <Route
                path="/registro-pacientes"
                element={
                    <RoutesWithoutNavBar>
                        <NewPatient />
                    </RoutesWithoutNavBar>
                }
            />
        </Routes>    
    );
}
 
export default RoutesMap;