import React from 'react';
import 'assets/css/spinner.scss';
import { Image } from 'react-bootstrap';
import ReactLogo from 'assets/img/icono-indicadores.svg'

const Loader = ({show}) => {
    return (
        <div className={`preloader ${show ? "" : "show"}`}>
            <Image className="loader-element" src={ReactLogo} height={40} />
        </div>
    )
}

export default Loader;