import { BrowserRouter } from "react-router-dom";
import { store } from "store/store";
import { Provider } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/css/global.css';
import RoutesMap from "routes/RoutesMap";

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <RoutesMap />
      </Provider>
    </BrowserRouter>
  );
}

export default App;
